<template>
  <div class="bg-cover md:flex  navbar  " :class="backgroundClass">
    <div class="max-w-con w-full">


      <div
        class="headerContainer  flex flex-row w-full py-[10px]  px-[20px] lg:px-[60px]  2xl:px-[0px] lg:py-0 h-full justify-center items-center ">


        <div class="flex   cursor-pointer !h-[100px]   justify-start items-center " @click="goHome">
          <img class="!w-[150px]   "
            src="https://imagedelivery.net/y3V2HCffVzC7jBDThnOGrw/5f1b6a96-e8dc-416a-f753-ac7d1556b100/public"
            alt="Logo" />
        </div>
        <div class="flex    w-full h-full">
          <MenuDesktop :current-menu="currentMenu" :menu-status="menuStatus" />
        </div>
        <div class="flex">


        </div>
        <div class="flex flex-row justify-center   items-center   h-full  ">
          <div v-show="!unsupportedNetwork" v-if="$user?.isLogined" @click="toggleDrawer()"
            class="flex flex-row gap-0 cursor-pointer">
            <div
              class="bg-gray-200 rounded-full md:rounded-[20px] flex items-center justify-center h-[48px] w-[48px]  mt-1 "
              v-if="$user.avatarPath">
              <img :src="$user.avatarPath" class="rounded-full h-[48px] w-[48px]" alt="avatar" />
            </div>
            <div class="bg-[#2C5A81] rounded-full flex items-center justify-center  h-[48px] w-[48px]  " v-else>
              <font-awesome-icon icon="fa-solid fa-user" class="flex justify-center items-center"
                style="color: white; height:12px" />
            </div>
            <div class="flex items-center cursor-pointer text-white justify-center pl-3.5 py-2" :title="$user?.account">
              <div class=" text-[8px] sm:text-[10px] lg:text-[16px] 2xl:text-[20px] "> {{
    $user?.account?.slice(0, 4)
  }}...{{
      $user?.account?.slice(-4) }}
              </div>

            </div>
          </div>
          <div v-else @click="$router.push('/login')" class="cursor-pointer loginContainer" :title="$user?.account">
            <div class="buttonText">

              Login
            </div>
          </div>
        </div>

        <div class=" ml-[8px] sm:ml-[30px] md:hidden  flex items-center">
          <div class="" @click="closeMenu">
            <div v-if="!menuStatus">
              <img src="@/assets/images/icon-burger-menu.svg" class=" h-[88px] w-[88px] sm:h-[44px] sm:w-[44px]" />
            </div>

            <div v-else>
              <img src="@/assets/images/icon-close-menu.svg" class=" h-[88px] w-[88px] sm:h-[44px] sm:w-[44px]" />
            </div>
          </div>
        </div>
      </div>
      <MenuMobile :current-menu="currentMenu" v-show="menuStatus" />
    </div>
  </div>
  <!-- <div :class="'py-2 fixed h-24 top-0 left-0 right-0 bg-white z-50 text-base '"
    style="box-shadow: 0 1px 2px hsl(0deg 0% 0% / 5%), 0 1px 4px hsl(0deg 0% 0% / 5%), 0 2px 8px hsl(0deg 0% 0% / 5%);z-index: 2023;"> -->
  <!-- <header class="mx-4">
      <div class="w-full flex flex-auto h-20 items-center ">
        <div class="flex items-center w-auto cursor-pointer" @click="goHome">
          <img class=" w-[6rem] md:w-[12.5rem] md:h-12  align-middle" src="@/assets/images/updated-logo.png"
            alt="Logo" />
          <div class="text-[32px] hidden sm:text-[20px] lg:text-[22px] lg:inline-block font-bold align-middle">
          </div>
        </div>



        <MenuDesktop class="ml-[100px]" :current-menu="currentMenu" :menu-status="menuStatus" />
        <div v-if="!shouldShowNavBar && wallet === 0">
          <WalletDropDown :result="true" />

        </div>
        <div v-else-if="!shouldShowNavBar && wallet === 1">
          <PowerUpMiningWalletDropdown :result="true" />

        </div>

        <div class=" absolute  right-[40px]  flex flex-row justify-center items-center" v-else>
          <div v-show="!unsupportedNetwork" v-if="$user?.isLogined" @click="toggleDrawer()"
            class="flex flex-row gap-0 cursor-pointer">
            <div class="bg-gray-200 rounded-full md:rounded-[20px] flex items-center justify-center h-8 w-8  mt-1 "
              v-if="$user.avatarPath">
              <img :src="$user.avatarPath" class="rounded-full w-6 h-6" alt="avatar" />
            </div>
            <div class="bg-[#2C5A81] rounded-full flex items-center justify-center h-8 w-8 mt-1 " v-else>
              <font-awesome-icon icon="fa-solid fa-user" class="flex justify-center items-center"
                style="color: white; height:12px" />
            </div>
            <div class="flex items-center cursor-pointer  justify-center px-3.5 py-2" :title="$user?.account">
              <span class="text-base md:text-[1rem] "> {{ $user?.account?.slice(0, 4) }}...{{
    $user?.account?.slice(-4) }} </span>
            </div>
          </div>
          <div v-else @click="$router.push('/login')"
            class="w-100 absolute  right-[10px]  md:w-35 text-base md:text-[22px] font-bold flex cursor-pointer colorful-text border px-2 md:px- py-1"
            :title="$user?.account">
            {{ $t('Login') }}
          </div>
        </div>

        <div class="  absolute  right-[10px] sm:hidden  flex items-center">
          <div class="pl-5 pr-2 md:p-5" @click="closeMenu">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" v-if="menuStatus" viewBox="0 0 20 20"
              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="block h-5 w-5 text-black" aria-hidden="true">
              <title>Close</title>
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" v-else viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="block h-5 w-5 text-black" aria-hidden="true">
              <title>Menu</title>
              <line x1="3" y1="12" x2="21" y2="12"></line>
              <line x1="3" y1="6" x2="21" y2="6"></line>
              <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
          </div>
        </div>
      </div>
    </header> -->

  <!-- <MenuMobile :current-menu="currentMenu" v-show="menuStatus" /> -->
  <!-- </div> -->
</template>

<script lang="ts">
import WalletDropDown from './WalletDropDown.vue';
import MenuDesktop from './MenuDesktop.vue';
import MenuMobile from './MenuMobile.vue';
import PowerUpMiningWalletDropdown from './PowerUpMiningWalletDropdown.vue';
import { supportedLanguages, currentLanguageDesc, languagesDict, currentLanguage } from '../i18n/i18n';
import { $user, pageConfig } from '../store/index';
import { NEED_LOGIN_PATHS } from '@/common/constant';
import { ElMessage } from 'element-plus';

export default {
  name: "HeaderView",
  components: {
    MenuDesktop,
    MenuMobile,
    WalletDropDown,
    PowerUpMiningWalletDropdown
  },
  props: {
    showOnboarding: { type: Boolean }
  },
  data() {
    return {
      currentMenu: '',
      menuStatus: false,
      nickname: '',
      unsupportedNetwork: false,
      user: {
        address: ""
      },
      $user,
      pageConfig,
      currentNetwork: {},
      languages: supportedLanguages,
      currentLanguageDesc: currentLanguageDesc(),
      currentLanguage: currentLanguage(),
      menuMobileResourceVisible: false,
      showYellowBg: false,
      wallet: 0,
    }
  },
  computed: {
    backgroundClass() {
      return (this as any).$route.name === 'home' || (this as any).$route.name === 'about' || (this as any).$route.name === 'login' ? 'bg-black' : 'bg-home bg-cover';
    },
    gradientStyle() {
      return {
        background: 'linear-gradient(to right, hsla(207, 44%, 42%, 1), hsla(214, 46%, 64%, 1), hsla(205, 40%, 37%, 1), hsla(26, 7%, 19%, 1))'
      };
    },

    shouldShowNavBar() {
      // @ts-ignore
      if (this.$route.path === '/short-term-mining') {
        (this as any).wallet = 0;
      }
      // @ts-ignore
      else if (this.$route.path === '/power-up-mining') {
        (this as any).wallet = 1;
      }
      // @ts-ignore
      return this.$route.path !== '/power-up-mining' && this.$route.path !== '/short-term-mining';
    },
  },
  watch: {
    $route(to, from) {


      (this as any).loginCheck(to.path);

      (this as any).showYellowBg = to.name === 'home' || to.name === 'about' || to.name === 'nodeInfo' || to.name === '/';
      // @ts-ignore
      (this as any).currentMenu = to.name;
      // @ts-ignore
      pageConfig.updateYellowBg(this.showYellowBg);
    }
  },
  mounted() {
    setTimeout(() => {
      (this as any).getLatestInfo();
    }, 1000);
  },
  methods: {

    async getLatestInfo() {
      (this as any).nickname = $user.nickname;



    },
    loginCheck(path: string) {
      if (!$user.isLogined && path && path !== '/' && NEED_LOGIN_PATHS.includes(path)) {
        let popupDisplayed = false; // Define popupDisplayed variable

        if (!popupDisplayed) {
          ElMessage.warning((this as any).$t('common.please-login'));
          popupDisplayed = true;
          (this as any).$router.push('/login');
        }
      }
    },
    toggleDrawer() {
      ((this as any).$parent as any).toggleDrawer();
      (this as any).menuStatus = false;
    },
    closeMenu() {
      (this as any).menuStatus = !(this as any).menuStatus;
      ((this as any).$parent as any).closeDrawer();
    },
    goHome() {
      (this as any).$router.push('/');
      ((this as any).$parent as any).closeDrawer();
      (this as any).menuStatus = false;
    },
    switchLang(key: string, value: string) {
      (this as any).$i18n.locale = key;
      localStorage.setItem("lang", key);
      (this as any).currentLanguageDesc = value;
      (this as any).currentLanguage = languagesDict[key as keyof object];
    },
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@700&display=swap');

.navbar {


  align-items: flex-start;
}

.headerContainer {
  display: flex;

  align-items: center;
  align-self: stretch;
}



.menu-content {
  position: relative;
  margin: auto;
  border-radius: 10px;
  width: 80%;
  max-width: 1260px;
  color: #35312E;
}

.router-select {
  position: relative;
}

.router-select:hover .menu-bg,
.menu-bg>div:hover {
  display: flex;
}



.menu-mobile {
  top: 97px;
  z-index: 2000;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 50px;
  height: fit-content;
}

.mobile-menu-bg {
  top: 97px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 2000;
  height: 100%;
  background-color: #1E1E1E;
}

.bg-home {
  background-image: url('@/assets/images/bg1.jpg');
  /* Replace with your image path */
}

.bg-cover {
  background-size: cover;
}

.bg-black {
  background-color: black;
}


.buttonText {
  color: #FFF;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

}

.loginContainer {
  border-radius: 64px;
  background: var(--Style, linear-gradient(270deg, #71BBFF 0%, #4048FF 100%));
  display: flex;
  padding: 12px 32px;
  width: 108px;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
</style>
